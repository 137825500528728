
const init = () => {
    const stickyEl = document.querySelector('.js-sticky-change-element');
    const rowEl = stickyEl.querySelector('.form-row');
    const navEl = document.querySelector('nav');
    const padding = 10;
    const setStickyTop = () => stickyEl.style.top = `-${rowEl.offsetTop - (navEl.offsetHeight || 50) - padding}px`;
    let resizeTimer;

    setStickyTop();

    window.addEventListener('resize', () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(setStickyTop, 100);
    })
}

export default init;
